import { map, pickBy, set } from 'lodash';

const getReviews = (doc: any) => {
  const x: Record<string, any> = {};
  console.log(doc);
  map(doc.content.reviews, (v: any, i: number) => {
    map(v.item, (vv, ii) => {
      console.log(v, i, vv, ii)
      x[`perf.[${i}].item[${ii}].rating`] = vv.rating;
      x[`perf.[${i}].item[${ii}].comments`] = vv.comments;
    });
  });

  console.log(x);
  return x;
};

export const documentToForm = (doc: any) => {
  getReviews(doc);
  const d = doc.content;
  return {
    ...d,
    mainProject1: d.mainProject?.[0],
    mainProject2: d.mainProject?.[1],
    mainProject3: d.mainProject?.[2],
    keyStrength0: d.keyStrength?.[0],
    keyStrength1: d.keyStrength?.[1],
    keyStrength2: d.keyStrength?.[2],
    areaDevelopment0: d.areaDevelopment?.[0],
    areaDevelopment1: d.areaDevelopment?.[1],
    areaDevelopment2: d.areaDevelopment?.[2],
    'developmentPlan0.skill': d.developmentPlan?.[0]?.skill,
    'developmentPlan0.support': d.developmentPlan?.[0]?.support,
    'developmentPlan0.timeLine': d.developmentPlan?.[0]?.timeLine,
    'developmentPlan1.skill': d.developmentPlan?.[1]?.skill,
    'developmentPlan1.support': d.developmentPlan?.[1]?.support,
    'developmentPlan1.timeLine': d.developmentPlan?.[1]?.timeLine,
    'developmentPlan2.skill': d.developmentPlan?.[2]?.skill,
    'developmentPlan2.support': d.developmentPlan?.[2]?.support,
    'developmentPlan2.timeLine': d.developmentPlan?.[2]?.timeLine,
    ...getReviews(doc),
  };
};


const setReviews = (doc: any, originalDoc: any) => {
  const filteredKeys = pickBy(doc, (v, k) => k.startsWith('perf.'));
  let newValue = originalDoc.reviews;

  map(filteredKeys, (v: any, k: string) => {
    set(newValue, k.replace('perf.', ''), v)
  });

  return newValue;
};

export const formToDocument = (doc: any, originalDoc: any) => {
  const reviews = setReviews(doc, originalDoc);
  console.log(reviews);
  return {
    areaDevelopment: [doc.areaDevelopment0 || '', doc.areaDevelopment1 || '', doc.areaDevelopment2 || ''],
    businessObjectives: doc.businessObjectives,
    department: doc.department,
    developmentPlan: [
      {
        skill: doc['developmentPlan0.skill'],
        support: doc['developmentPlan0.support'],
        timeLine: doc['developmentPlan0.timeLine'],
      },
      {
        skill: doc['developmentPlan1.skill'],
        support: doc['developmentPlan1.support'],
        timeLine: doc['developmentPlan1.timeLine'],
      },
      {
        skill: doc['developmentPlan2.skill'],
        support: doc['developmentPlan2.support'],
        timeLine: doc['developmentPlan2.timeLine'],
      },
    ],
    keyStrength: [doc.keyStrength0 || '', doc.keyStrength1 || '', doc.keyStrength2 || ''],
    mainProject: [doc.mainProject1 || '', doc.mainProject2 || '', doc.mainProject3 || ''],
    managerName: doc.managerName,
    name: doc.name,
    overallComments: doc.overallComments,
    overallRating: doc.overallRating,
    performanceSummary: doc.performanceSummary,
    performanceSummaryOthers: doc.performanceSummaryOthers,
    promotion: doc.promotion,
    promotionComments: doc.promotionComments,
    reviews,
    x:[
      {
        "categoryDescription": "Problem-solving skills",
        "item": [
          {
            "description": "Ability to tackle and solve the frequent problems",
            "rating": 0,
            "comments": ""
          },{
            "description": "Ensures business continuity in emergencies or crises",
            "rating": 0,
            "comments": ""
          }
        ]
      },
      {
        "categoryDescription": "Management skills",
        "item": [
          {
            "description": "Effective people/resource management; manages the team’s time",
            "rating": 0,
            "comments": ""
          },
          {
            "description": "Provides team members with timely and effective coaching and feedback",
            "rating": 0,
            "comments": ""
          }
        ]
      },{
        "categoryDescription": "Empathy & Relationship building",
        "item": [
          {
            "description": "Strong empathy skills that can foster a trusting work environment",
            "rating": 0,
            "comments": ""
          },
          {
            "description": "Connect, build, and sustain relationships",
            "rating": 0,
            "comments": ""
          }
        ]
      },{
        "categoryDescription": "Communication",
        "item": [
          {
            "description": "Has relationship building and negotiation skills",
            "rating": 0,
            "comments": ""
          },
          {
            "description": "Able to communicate effectively, motivate and coach team members",
            "rating": 0,
            "comments": ""
          },
          {
            "description": "Builds and facilitates communication across iHealth",
            "rating": 0,
            "comments": ""
          }
        ]
      },{
        "categoryDescription": "Expertise",
        "item": [
          {
            "description": "Strong expertise in specific area",
            "rating": 0,
            "comments": ""
          }
        ]
      },{
        "categoryDescription": "Mission & Values",
        "item": [
          {
            "description": "Upholds the iHealth’s mission",
            "rating": 0,
            "comments": ""
          },{
            "description": "Represents iHealth’s values",
            "rating": 0,
            "comments": ""
          },{
            "description": "Demonstrates a team spirit",
            "rating": 0,
            "comments": ""
          }
        ]
      }
    ],
    title: doc.title,
  };
};
