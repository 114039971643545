import { ReactNode } from "react";

export interface TitleAndSubtitleComponentProps {
  title?: ReactNode;
  subtitle?: ReactNode;
}

export const TitleAndSubtitleComponent = ({
  title,
  subtitle,
}: TitleAndSubtitleComponentProps) => {
  return (
    <div className="title-subtitle-component">
      {title && <div className="title">{title}</div>}
      {subtitle && <div>[{subtitle}]</div>}
    </div>
  );
};
