import { Select } from "antd";

export interface PromotionRatingComponentProps {
  value?: string;
  onChange?: (v: string) => void;
  disabled?: boolean;
}

const options = [
  { value: '3', label: '3 = Ready now' },
  { value: '2', label: '2 = Ready within 1-2 years' },
  { value: '1', label: <span>1 = Ready &#8805; 3 years</span> },
  { value: '0', label: '0 = Not ready' },
];

export const PromotionRatingComponent = ({
  value,
  onChange,
  disabled,
}: PromotionRatingComponentProps) => {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
