import { FormItemProps as BaseFormItemProps, Form } from "antd";

export interface FormItemProps extends BaseFormItemProps {
  required?: boolean;
}

export const FormItem = ({
  required = true,
  ...props
}: FormItemProps) => {
  return (
    <Form.Item {...props} rules={[{ required: false }]} hasFeedback={required} />
  );
};
