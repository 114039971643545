import { useEffect, useState } from "react";
import { PerformanceReviewFormComponent } from "./PerformanceReviewFormComponent";
import querystring from 'query-string';
import axios from "axios";
import { Button, Input, Space, Spin, message } from "antd";
import { documentToForm, formToDocument } from "./documentMapper";

const API_URL = process.env.REACT_APP_API_URL || 'https://review-form.ihealth-eng.com';

export const PerformanceReviewContainer = () => {
  const [documentId] = useState(querystring.parse(window.location.search).documentId);
  const [tempToken, setTempToken] = useState<string>();
  const [token, setToken] = useState<string>();
  const [error, setError] = useState<string>();
  const [isVerifyLoading, setIsVerifyLoading] = useState(true);
  const [verifiedToken, setVerifiedToken] = useState<any>();
  const [isDocLoading, setIsDocLoading] = useState(true);
  const [docValue, setDocument] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const sendTokenReq = async () => {
      try {
        setIsVerifyLoading(true);
        const response = await axios.post(API_URL + '/api/verifyToken', {
          token,
          documentId
        });
        if (response.data.success) {
          setVerifiedToken(response.data);
        } else {
          throw new Error();
        }
      } catch (error) {
        console.error(error);
        setError('Failed to verify the token!');
      } finally {
        setIsVerifyLoading(false);
      }
    };

    if (token && documentId) {
      sendTokenReq();
    }
  }, [token, documentId]);

  useEffect(() => {
    const sendGetDocument = async () => {
      const { documentId } = querystring.parse(window.location.search);
      try {
        setIsDocLoading(true);
        const response = await axios.get(API_URL + `/api/documents/${documentId}`, {
          headers: {
            Authorization: `Bearer ${verifiedToken.sessionToken}`,
          },
        });
        setDocument(documentToForm(response.data));
      } catch (error) {
        console.error(error);
        setError('Failed to fetch the document!');
      } finally {
        setIsDocLoading(false);
      }
    };

    if (verifiedToken?.sessionToken) {
      sendGetDocument();
    }
  }, [verifiedToken]);


  const handleSubmit = async (value: any) => {
    const { documentId } = querystring.parse(window.location.search);
    try {
      setIsSubmitting(true);
      const response = await axios.put(API_URL + `/api/documents/${documentId}`, {
        id: documentId,
        content: formToDocument(value, docValue),
      }, {
        headers: {
          Authorization: `Bearer ${verifiedToken.sessionToken}`,
        },
      });
      message.success('Submitted the form successfully!');
      console.log(response);
    } catch (error) {
      console.error(error);
      setError('Failed to update the document!');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!token) {
    return (
      <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Space>
          <div>Please input your token:</div>&nbsp;
          <Input onChange={(evt) => setTempToken(evt.target.value || '')} style={{ width: 400 }} placeholder="Token" />
          <Button type="primary" onClick={() => setToken(tempToken as string)} className="submit-button">Submit</Button>
        </Space>
      </div>
    )
  }

  if (!isVerifyLoading && verifiedToken && !isDocLoading && docValue) {
    return (
      <PerformanceReviewFormComponent
        role={verifiedToken.role}
        onSubmit={handleSubmit}
        initialValues={docValue}
        isSubmitting={isSubmitting}
      />
    );
  }

  if (isVerifyLoading || isDocLoading) {
    return <Spin />;
  }

  return null;
};
