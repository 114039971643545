import { Select } from "antd";

export interface PerformanceRatingSelectComponentProps {
  value?: string;
  onChange?: (v: string) => void;
  disabled?: boolean;
}

const options = [
  { value: '4', label: '4 = (Far) Exceeded expectations' },
  { value: '3', label: '3 = Successfully met expectations' },
  { value: '2', label: '2 = Needs some improvement' },
  { value: '1', label: '1 = Off track' },
  { value: 'N/A', label: 'N/A = Not applicable' },
];

export const PerformanceRatingSelectComponent = ({
  value,
  onChange,
  disabled,
}: PerformanceRatingSelectComponentProps) => {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
