import { Button, Col, Form, Input, Row, Space } from "antd";
import { SectionHeaderComponent } from "./SectionHeaderComponent";
import { TitleAndSubtitleComponent } from "./TitleAndSubtitleComponent";
import { RatingSelectComponent } from "./RatingSelectComponent";
import { PromotionRatingComponent } from "./PromotionRatingComponent";
import { PerformanceRatingSelectComponent } from "./PerformanceRatingSelectComponent";
import { FormItem } from "./FormItem";

import './PerformanceReviewFormComponent.css';

export interface SubmitValue {

}

export enum RoleType {
  EMPLOYEE = 'EMPLOYEE',
  MANAGER = 'MANAGER',
  DEPARTMENT_HEAD = 'DEPARTMENT_HEAD',
}

export interface PerformanceReviewFormComponentProps {
  role: RoleType;
  initialValues: any;
  onSubmit?: (value: SubmitValue) => void;
  isSubmitting?: boolean;
}

export const PerformanceReviewFormComponent = ({
  role,
  initialValues,
  onSubmit,
  isSubmitting,
}: PerformanceReviewFormComponentProps) => {
  const isNotEmployee = role !== RoleType.EMPLOYEE;
  const isEmployee = role === RoleType.EMPLOYEE;
  const isNotManager = role !== RoleType.MANAGER;
  const isManager = role === RoleType.MANAGER;
  const isNotDepartmentHead= role !== RoleType.DEPARTMENT_HEAD;
  const isDepartmentHead= role === RoleType.DEPARTMENT_HEAD;

  const renderRatingTitle = () => {
    return (
      <div>Rating - <span className="danger">For Managers Only</span></div>
    );
  };

  const renderPromotion = () => {
    return (
      <div>Outlook for a promotion - <span className="danger">For Managers Only</span></div>
    );
  };

  const renderRecommendedRating = () => {
    return (
      <div className="recommended-rating-info">
        <TitleAndSubtitleComponent title="Recommended rating" />
        <div>4 = Far Exceeded expectations</div>
        <div>3 = Successfully met expectations</div>
        <div>2 = Met some expectations but needs improvement</div>
        <div>1 = Off track</div>
        <div>N/A = too new to the company or special circumstances</div>
      </div>
    );
  };

  const renderPerformanceTableRow = (v: any, i: number) => {
    return (
      <tr key={i}>
        <td className="column-1">
          <Row>
            <Col span={12} className="orange-wrapper category">
              {v.categoryDescription}
            </Col>
            <Col span={12}>
              <Space direction="vertical" className="w100" size={0}>
                {v.item.map((ci: any) => (
                  <div className="orange-wrapper">
                    {ci.description}
                  </div>
                ))}
              </Space>
            </Col>
          </Row>
        </td>
        <td className="column-2">
          <Row>
            <Col span={24}>
              <Space direction="vertical" className="w100" size={12}>
                  {v.item.map((ci: any, ii: number) => (
                    <FormItem name={`perf.[${i}].item[${ii}].rating`} key={ii} required>
                      <PerformanceRatingSelectComponent disabled={isNotDepartmentHead} />
                    </FormItem>
                  ))}
                </Space>
            </Col>
          </Row>
        </td>
        <td className="column-3">
          <Row>
            <Col span={24}>
              <Space direction="vertical" className="w100" size={12}>
                {v.item.map((ci: any, ii: number) => (
                  <FormItem name={`perf.[${i}].item[${ii}].comments`} required={false}>
                    <Input disabled={isNotManager && isNotDepartmentHead} />
                  </FormItem>
                ))}
                </Space>
            </Col>
          </Row>
        </td>
      </tr>
    )
  }

  return (
    <Form
      onFinish={onSubmit}
      initialValues={initialValues}
      className="performance-review-form-comp"
    >
      {/* Page one */}
      <Row>
        <Space direction="vertical" className="main-info">
          <FormItem name="name" label="Name">
            <Input disabled={isNotEmployee} />
          </FormItem>
          <FormItem name="title" label="Title">
            <Input disabled={isNotEmployee} />
          </FormItem>
          <FormItem name="department" label="Department">
            <Input disabled={isNotEmployee} />
          </FormItem>
        </Space>
      </Row>
      <Row gutter={10} className="form-1 mb100">
        <Col span={6}>
          <div>
            <SectionHeaderComponent title="Main Projects this year" />
            <Space direction="vertical" className="w100">
              <FormItem name="mainProject1" label="Main Project 1">
                <Input.TextArea disabled={isNotEmployee} />
              </FormItem>
              <FormItem name="mainProject2" label="Main Project 2">
                <Input.TextArea disabled={isNotEmployee} />
              </FormItem>
              <FormItem name="mainProject3" label="Main Project 3">
                <Input.TextArea disabled={isNotEmployee} />
              </FormItem>
            </Space>
          </div>
        </Col>
        <Col span={9}>
          <div>
            <SectionHeaderComponent title="Summary for Objectives" />
            <div className="orange-wrapper">
              <TitleAndSubtitleComponent
                title="Business Objectives"
                subtitle="Insert summary of objectives"
              />
              <FormItem name="businessObjectives">
                <Input.TextArea rows={7} disabled={isNotEmployee} />
              </FormItem>
            </div>
            <div className="orange-wrapper">
              <TitleAndSubtitleComponent
                title={renderRatingTitle()}
                subtitle="Insert rating of overall performance"
              />
              <FormItem name="overallRating">
                <RatingSelectComponent disabled={isNotDepartmentHead} />
              </FormItem>
            </div>
          </div>
          {renderRecommendedRating()}
        </Col>
        <Col span={9}>
          <div>
            <SectionHeaderComponent title="Summary for Achievements" />
            <div className="orange-wrapper">
              <TitleAndSubtitleComponent
                title="Performance Summary & Achievement"
                subtitle="Insert summary of overall performance evaluation"
              />
              <FormItem name="performanceSummary">
                <Input.TextArea disabled={isNotEmployee} />
              </FormItem>
              <TitleAndSubtitleComponent
                title="Other Contributions"
              />
              <FormItem name="performanceSummaryOthers">
                <Input.TextArea disabled={isNotEmployee} />
              </FormItem>
            </div>
          </div>
          <div className="orange-wrapper">
            <TitleAndSubtitleComponent
              title={renderPromotion()}
              subtitle="Is this person with potential and outlook for a promotion?"
            />
            <FormItem name="promotion">
              <PromotionRatingComponent disabled={isEmployee} />
            </FormItem>
            <TitleAndSubtitleComponent
              subtitle="Insert comment"
            />
            <FormItem name="promotionComments">
              <Input.TextArea disabled={isEmployee}  />
            </FormItem>
          </div>
        </Col>
      </Row>

      {/* Page two */}
      <Row gutter={10} className="form-2 mb100">
        <table className="performance-table">
          <thead>
            <tr>
              <td className="column-1">
                <div>Performance Requirements - <span className="warning">For managers Only</span></div>
                <Space align="start">
                 <div>Name</div>
                  <FormItem name="managerName" className="m0">
                    <Input disabled={isEmployee} />
                  </FormItem>
                </Space>
              </td>
              <td className="column-2">
                Rating
              </td>
              <td className="column-3">
                <div>Evaluation comments - <span className="warning">For managers Only</span></div>
                <div className="evaluation-comments-subtext">
                  Describe the employee's performance against each Performance Requirement, and observed behaviors. (Not required to comment)
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {initialValues?.reviews?.map(renderPerformanceTableRow)}
            <tr className="performance-table-summary">
              <td className="column-1">
                Summary
                <div className="evaluation-comments-subtext">
                  Decide you discretionary rating and evaluation of overall performance in relation to Performance Requirements.
                </div>
              </td>
              <td className="column-2">
                <FormItem name="overallRating">
                  <RatingSelectComponent disabled={isNotDepartmentHead} />
                </FormItem>
              </td>
              <td className="column-3">
                <FormItem name="overallComments">
                  <Input.TextArea disabled={isEmployee} />
                </FormItem>
              </td>
            </tr>
          </tbody>
        </table>
        <Space size={50} className="w100 orange-wrapper">
          <Space direction="vertical">
            <strong>Rating:</strong>
            <div>4 = (Far) Exceeded expectations</div>
            <div>3 = Successfully met expectations</div>
            <div>2 = Needs some improvement</div>
            <div>1 = Off track</div>
            <div>N/A = Not applicable</div>
          </Space>
          <Space direction="vertical">
            <strong>Description:</strong>
            <div>Far exceeded expectations and made significant contributions.</div>
            <div>Successfully delivered on all important aspects and satisfactory performance.</div>
            <div>Showed adequate performance but needs some improvement.</div>
            <div>Outcomes were far below expectations in most areas of responsibility.</div>
            <div>No performance records exist.</div>
          </Space>
        </Space>
      </Row>

      {/* Page three */}
      <Row className="form-3">
        <table className="development-table">
          <thead>
            <td className="column-1" colSpan={2}>
              <div>Development - <span className="warning">For Employees</span></div>
              <Space align="start">
                <div>Name</div>
                <FormItem name="name">
                  <Input disabled={isNotEmployee} />
                </FormItem>
              </Space>
            </td>
          </thead>
          <tbody>
            <tr className="key-strengths">
              <td className="column-1">
                Key strengths
                <div className="evaluation-comments-subtext">
                  List and elaborate on key strengths
                </div>
              </td>
              <td className="column-2">
                <Space direction="vertical" className="w100">
                  {[0, 1, 2].map((i) => (
                    <div key={i} className="w100">
                      <FormItem label={i + 1} name={`keyStrength${i}`} required={false}>
                        <Input.TextArea disabled={isNotEmployee} />
                      </FormItem>
                    </div>
                  ))}
                </Space>
              </td>
            </tr>
            <tr className="areas-for-development">
              <td className="column-1">
                Areas for development
                <div className="evaluation-comments-subtext">
                  List and elaborate on areas for development
                </div>
              </td>
              <td className="column-2">
                <Space direction="vertical" className="w100">
                  {[0, 1, 2].map((i) => (
                    <div key={i} className="w100">
                      <FormItem label={i + 1} name={`areaDevelopment${i}`} required={false}>
                        <Input.TextArea disabled={isNotEmployee} />
                      </FormItem>
                    </div>
                  ))}
                </Space>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="development-plan-table">
          <thead>
            <td className="column-1" colSpan={3}>
              <div>Development Plan</div>
            </td>
          </thead>
          <tbody>
            <tr className="development-plan-header">
              <td className="column-1">
                Skills and competencies to develop
              </td>
              <td className="column-2">
                Activities to support development of skills and competencies
              </td>
              <td className="column-3">
                Timeline
              </td>
            </tr>
            {[0, 1, 2].map((i) => (
              <tr key={i}>
                <td className="column-1">
                  <FormItem name={`developmentPlan${i}.skill`} required={false}>
                    <Input.TextArea disabled={isNotEmployee} />
                  </FormItem>
                </td>
                <td className="column-2">
                  <FormItem name={`developmentPlan${i}.support`} required={false}>
                    <Input.TextArea disabled={isNotEmployee} />
                  </FormItem>
                </td>
                <td className="column-3">
                  <FormItem name={`developmentPlan${i}.timeLine`} required={false}>
                    <Input.TextArea disabled={isNotEmployee} />
                  </FormItem>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Row>

      <Row style={{ float: 'right' }}>
        <Button htmlType="submit" type="primary" className="submit-button" loading={isSubmitting} disabled={isSubmitting}>Submit</Button>
      </Row>
    </Form>
  );
};
