export interface SectionHeaderComponentProps {
  title: string;
}

export const SectionHeaderComponent = ({
  title,
}: SectionHeaderComponentProps) => {
  return (
    <div className="section-header-component">
      {title}
    </div>
  );
};
