import { Select } from "antd";

export interface RatingSelectComponentProps {
  value?: string;
  onChange?: (v: string) => void;
  disabled?: boolean;
}

const options = [
  { value: '4', label: '4 = Far Exceeded expectations' },
  { value: '3', label: '3 = Successfully met expectations' },
  { value: '2', label: '2 = Met some expectations but needs improvement' },
  { value: '1', label: '1 = Off track' },
  { value: 'N/A', label: 'N/A = too new to the company or special circumstances' },
];

export const RatingSelectComponent = ({
  value,
  onChange,
  disabled,
}: RatingSelectComponentProps) => {
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
